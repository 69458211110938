<template>
  <div>
    <portal to="allow-audio-modal">
      <div class="allow-audio-modal" @click="closeModal"></div>
      <div v-show="content" class="allow-audio-wrap">
        <div class="title">
          <div class="icon">
            <img src="@/assets/icons/audio.gif" />
          </div>
          <div>{{ title }}</div>
        </div>
        <textarea id="announcement" class="content" cols="30" rows="1" v-model="content"></textarea>
        <div class="btm">
          <div class="btn-wrap confirm-btn" @click="allowAudio">{{ $t('ok') }}</div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AllowAudio',
  data() {
    return {
      // content: `一、查詢案由務必詳細完整填寫，例:王小明毒品案。\n  （勿填偵辦刑案、王O明毒品案、毒品案….）\n二、單位主管每月需稽核同仁使用情況，稽核紀錄目前\n   永久保存。(務必因公務查詢使用)\n三、車辨歷史資料保存一年。`,
    }
  },
  computed: {
    ...mapState(['webAnnouncements']),
    content() {
      return this.webAnnouncements.length > 0 ? this.webAnnouncements[0].content : this.$t('alarm_info')
    },
    title() {
      return this.content?.includes(this.$t('alarm_info')) ? this.$t('alarm_hint') : this.$t('alarm_announcement')
    },
  },
  watch: {
    content() {
      setTimeout(() => {
        this.CalTextareaHeight()
      }, 30)
    }
  },
  mounted() {
    setTimeout(() => {
      this.CalTextareaHeight()
    }, 30)
  },
  methods: {
    closeModal() {
      this.$emit('closemodal')
    },
    allowAudio() {
      this.closeModal()
    },
    CalTextareaHeight() {
      const ta = document.getElementById('announcement')
      let taHeight = ta.scrollHeight
      let numberOfLines = Math.floor(taHeight / 24)
      let newHeight = numberOfLines === 1 ? 24 : numberOfLines * 24 + 24
      ta.style.height = newHeight + 'px'
    }
  }
}
</script>

<style scoped>
.allow-audio-modal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 44, 57, 0.6);
  z-index: 3;
}

.allow-audio-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  box-sizing: border-box;
  background-color: #f2fdfc;
  border-radius: 3px;
  z-index: 3;
  padding: 20px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  display: flex;
  gap: 5px;
}

.title div:nth-child(2) {
  margin-left: 3px;
}

.content {
  outline: none;
  border: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: justify;
  height: 24px;
  resize: none;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 10px;
  border-radius: 5px;
  pointer-events: none;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}

.btm {
  display: flex;
  justify-content: flex-end;
}

.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  cursor: pointer;
  color: #343b5e;
  width: 90px;
  height: 32px;
  box-sizing: border-box;
  font-size: 16px;
}

.confirm-btn {
  margin-left: 5px;
  background-color: #343b5e;
  color: #fff;
}

.cancel-btn {
  border: 1px solid #546e95;
  color: #546e95;
}

img {
  width: 24px;
  height: 24px;
}
</style>